import ApiService from '@/common/api/api.service';

const basePath = 'Protocolo';

const ProtocoloService = {
  async obterTodosPaginado(filtro, paginacao) {
    const { data } = await ApiService.get(`${basePath}/obter-por-filtro`, {
      params: {
        ...filtro,
        ...paginacao,
      },
    });
    return data;
  },
  async atualizar(form) {
    const { data } = await ApiService.put(`${basePath}`, form);
    return data;
  },
  async buscarAnexo(id) {
    const { data } = await ApiService.get(
      `${basePath}/recuperar-anexo?id=${id}`,
      {
        id,
      }
    );
    return data;
  },
};

export default ProtocoloService;
