<template>
  <div>
    <titulo-protocolos :breadcrumbs="breadcrumbs" />
    <tabela-protocolos
      :items="protocolos"
      @responder-protocolo="abrirModalAtualizar"
    />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="pegarProtocolos"
    />
    <modal-protocolo
      @fechar="fecharModalAtualizar"
      :exibir="modal.exibir"
      :dados="modal.data"
    />
  </div>
</template>

<script>
// Aux & Utils
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// services
import ProtocoloService from '@/common/services/protocolo/protocolo.service.js';

// components
import ModalProtocolo from './components/ModalProtocolo.vue';
import TituloProtocolos from '@/views/rh/protocolos/components/TituloProtocolos.vue';
import TabelaProtocolos from './components/TabelaProtocolos';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  name: 'Protocolo',
  components: { TituloProtocolos, TabelaProtocolos, Paginacao, ModalProtocolo },

  data() {
    return {
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      breadcrumbs: [
        { titulo: this.$t('RH.RECURSOS_HUMANOS') },
        { titulo: this.$t('RH.PROTOCOLOS') },
      ],
      protocolos: [],
      modal: {
        data: {},
        exibir: false,
      },
    };
  },

  mounted() {
    this.pegarProtocolos();
  },

  methods: {
    pegarProtocolos() {
      this.$store.dispatch(START_LOADING);
      ProtocoloService.obterTodosPaginado({}, this.paginacao)
        .then(({ data }) => {
          this.protocolos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    abrirModalAtualizar(item) {
      this.modal.data = item;
      this.modal.exibir = true;
    },

    fecharModalAtualizar() {
      this.modal.data = {};
      this.modal.exibir = false;
      this.pegarProtocolos();
    },
  },
};
</script>
