<template>
  <b-table
    :fields="tabela.fields"
    :items="items"
    responsive="sm"
    :empty-text="$t('RH.NENHUM_REGISTRO')"
    head-variant="light"
    class="mt-3"
    selectable
    show-empty
    striped
    hover
  >
    <template #cell(resposta)="item">
      <span :class="getRespostaClass(item.value)">
        {{ item.value ? $t('GERAL.SIM') : $t('GERAL.NAO') }}
      </span>
    </template>
    <template #cell(acoes)="item">
      <b-button variant="primary" @click="responderProtocolo(item)">
        {{ $t('RH.RESPONDER_PROTOCOLO') }}
      </b-button>
    </template>
  </b-table>
</template>
<script>
import datas from '@/common/utils/datas';

export default {
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'dataCriacao',
            label: this.$t('ACADEMICO.DATA_CRIACAO'),
            sortable: true,
            formatter: (value) => {
              return value ? datas.formatDateToShow(value) : '';
            },
          },
          {
            key: 'nomeUsuario',
            label: this.$t('ACADEMICO.SOLICITANTE'),
            sortable: true,
          },
          {
            key: 'nomeAluno',
            label: this.$t('ACADEMICO.ALUNO'),
            sortable: true,
          },
          {
            key: 'descricao',
            label: this.$t('GERAL.DESCRICAO'),
            sortable: true,
          },
          {
            key: 'resposta',
            label: this.$t('GERAL.RESPONDIDO'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
    };
  },
  methods: {
    responderProtocolo(item) {
      this.$emit('responder-protocolo', item.item);
    },
    getRespostaClass(value) {
      return value ? 'text-success' : 'text-danger';
    },
  },
};
</script>
