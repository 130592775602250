<template>
    <topbar
      :titulo="$t('RH.PROTOCOLOS')"
      :subtitulo="$t('RH.PROTOCOLOS_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />
  </template>
  <script>
  // Utils & Aux:
//   import rotas from '@/common/utils/rotas';
  import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
  
  export default {
    name: 'TituloProtocolos',
    props: {
      breadcrumbs: { type: Array, default: Array },
    },
    components: { Topbar },
  };
  </script>