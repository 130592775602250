<template>
  <div>
    <modal
      :titulo="$t('RH.FORMULARIO_PROTOCOLO')"
      id="modalMotivoCancelamento"
      :exibir="exibir"
      @fechar="fecharModal"
      :campos="$refs"
      :ocultarRodape="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <b-col cols="12">
                <input-text
                  v-model="dados.nomeUsuario"
                  ref="solicitante"
                  :label="$t('RH.SOLICITANTE')"
                  disabled
                />
              </b-col>
              <b-col md="12">
                <input-text-area
                  ref="descricao"
                  v-model="dados.descricao"
                  :label="$t('ACADEMICO.SOLICITACAO')"
                  :placeholder="$t('ACADEMICO.SOLICITACAO')"
                  disabled
                />
              </b-col>
              <b-col cols="12" class="mb-2">
                <h6>{{ $t('RH.ANEXO') }}:</h6>
                <div md="12" v-if="imagemAnexo">
                  <img
                    alt="anexo"
                    :src="imagemAnexo"
                    class="img-fluid cover"
                    @click="downloadImagem"
                  />
                  <b-button
                    variant="primary"
                    class="w-100 mt-2"
                    @click="downloadImagem"
                  >
                    {{ $t('GERAL.BAIXAR') }}
                  </b-button>
                </div>
                <span v-else class="text-danger">
                  {{ $t('GERAL.SEM_ANEXO') }}
                </span>
              </b-col>
              <b-col cols="12">
                <input-text-area
                  v-model="form.resposta"
                  ref="resposta"
                  :label="$t('RH.RESPOSTA')"
                  required
                />
              </b-col>
            </b-row>
            <div class="text-right d-flex space-between mt-3">
              <b-button variant="secondary" @click="fecharModal">
                {{ $t('GERAL.FECHAR') }}
              </b-button>
              <b-button class="ml-2" variant="primary" @click="salvar">
                {{ $t('GERAL.SALVAR') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// Models:

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

//Components:
import { InputText, InputTextArea } from '@/components/inputs';
import Modal from '@/components/modal/Modal.vue';

// Services:
import ProtocoloService from '@/common/services/protocolo/protocolo.service.js';

export default {
  name: 'ModalMotivoCancelamento',
  components: {
    Modal,
    InputText,
    InputTextArea,
  },
  props: {
    exibir: { type: Boolean, default: false },
    dados: { type: Object, default: Object },
  },
  data() {
    return {
      imagemAnexo: '',
      imagemNome: '',
      form: {
        resposta: '',
      },
    };
  },
  watch: {
    dados: {
      handler() {
        this.getAnexo();
        this.form.resposta = this.dados.resposta;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    baixarAnexo() {
      if (this.dados.anexo.documento) {
        let bytes = pdf.base64ToArrayBuffer(this.dados.anexo.documento);

        // converte o array em blob
        let blob = new Blob([bytes], { type: 'application/pdf' });

        // faz download do PDF:
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.dados.anexo.nomeArquivo;
        link.click();
      }
    },
    async getAnexo() {
      if (!this.dados?.anexo) return;
      try {
        this.$store.dispatch(START_LOADING);

        const { data } = await ProtocoloService.buscarAnexo(this.dados.id);
        this.imagemAnexo = 'data:image/jpg;base64,' + data.documento;
        this.imagemNome = data.nomeArquivo;
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    downloadImagem() {
      const link = document.createElement('a');
      link.href = this.imagemAnexo;
      link.download = 'anexo.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async salvar() {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);

      try {
        const { data } = await ProtocoloService.atualizar({
          descricao: this.dados.descricao,
          resposta: this.form.resposta,
          usuarioId: this.dados.usuarioId,
          matriculaId: this.dados.matriculaId,
          id: this.dados.id,
        });
        mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
        this.fecharModal();
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    fecharModal() {
      this.$emit('fechar');
      this.imagemAnexo = '';
      this.form = {};
    },
  },
};
</script>
